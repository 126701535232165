import { LimitOffsetInput } from "__generated__/graphql";
import { FieldInfo, UpdatesConfig } from "@urql/exchange-graphcache";

import {
  BatchCreateBackupFileMutation,
  BatchCreateBackupFileMutationVariables,
  CreateBackupFileMutation,
  CreateBackupFileMutationVariables,
} from "../mutations.generated";

const QUERY_KEY = "backupFiles";

type BatchCreateBackupFileMutationInputType = {
  filters: {
    company?: {
      id: {
        exact: string;
      };
    };
  };
  pagination?: LimitOffsetInput;
};

type FieldInfoProm = FieldInfo & {
  arguments: BatchCreateBackupFileMutationInputType;
};

const batchCreateBackupUpdateConfig: Partial<UpdatesConfig>["Mutation"] = {
  batchCreateBackupFile(
    result: BatchCreateBackupFileMutation,
    _args: { data: BatchCreateBackupFileMutationVariables },
    cache,
    _info
  ) {
    (cache.inspectFields("Query") as FieldInfoProm[])
      .filter((field) => field.fieldName === QUERY_KEY)
      .forEach((field) => {
        const { arguments: args } = field;

        if (args?.filters?.company) {
          return cache.invalidate("Query", field.fieldKey);
        }

        return null;
      });
  },
  createBackupFile(
    result: CreateBackupFileMutation,
    _args: { data: CreateBackupFileMutationVariables },
    cache,
    _info
  ) {
    (cache.inspectFields("Query") as FieldInfoProm[])
      .filter((field) => field.fieldName === QUERY_KEY)
      .forEach((field) => {
        const { arguments: args } = field;

        if (args?.filters?.company) {
          return cache.invalidate("Query", field.fieldKey);
        }

        return null;
      });
  },
};

export { batchCreateBackupUpdateConfig };
