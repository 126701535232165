import { devtoolsExchange } from "@urql/devtools";
import { batchCreateBackupUpdateConfig } from "components/DeductionsNext/DeductionsReconciliationNext/Transaction/urqlUpdateConfig";
import {
  createFavoriteBackupConfig,
  createFavoriteBackupOptimisticConfig,
} from "components/DeductionsNext/DeductionsScanning/DeductionScanner/urqlUpdateConfig";
import {
  promStatusOptimisticConfig,
  promStatusUpdateConfig,
} from "components/PlanningNext/Views/Columns/optimisticConfig";
import { apiRootPath, cresicorNextServiceURL, isRunningTests } from "configs";
import {
  CombinedError,
  createClient,
  dedupExchange,
  fetchExchange,
  mapExchange,
  Operation,
} from "urql";
import { cacheExchange } from "@urql/exchange-graphcache";
import {
  addproductGroupHierarchyConfig,
  addproductHierarchyConfig,
  moveProductGroupHierarchyConfig,
} from "components/HierarchyNext/ProductHierarchy/urql/updateConfig";

export const makeVividlyClient = (
  options: Partial<Parameters<typeof createClient>[0]>
) =>
  createClient({
    url: `${cresicorNextServiceURL}/${apiRootPath}graphql/`,
    fetchOptions: { credentials: "include" },
    requestPolicy: "cache-and-network",
    maskTypename: true,
    // A note on exchanges: make sure that all synchronous exchanges are listed before any asynchronous ones
    // https://commerce.nearform.com/open-source/urql/docs/advanced/authoring-exchanges/#synchronous-first-asynchronous-last
    exchanges: [
      // devtoolsExchange isn't used when testing to avoid the following error:
      // TypeError: Cannot read properties of null (reading '_location')
      // at /CresicorNext/frontend/node_modules/jsdom/lib/jsdom/browser/Window.js:375:79
      ...(isRunningTests() ? [] : [devtoolsExchange]),
      dedupExchange,
      cacheExchange({
        // The folllowing urql configurations dictate how our urql cache should be updated after a specified mutation is finished.
        // For example, on successfully creating a record from a createRecord mutation, we can manually update our urql cache of Records
        // so that our frontend shows the new record, without having to make another network call to fetch records.
        // This helps in situations where we need to prop-drill a bunch of refetch functions in order to keep our frontend in sync.
        // APIs and more info here: https://formidable.com/open-source/urql/docs/graphcache/cache-updates/
        updates: {
          Mutation: {
            ...promStatusUpdateConfig,
            ...batchCreateBackupUpdateConfig,
            ...createFavoriteBackupConfig,
            ...addproductHierarchyConfig,
            ...addproductGroupHierarchyConfig,
            ...moveProductGroupHierarchyConfig,
          },
        },
        // The folllowing urql configurations dictate how our urql cache should be updated optimistically after a specified mutation is triggered.
        // For example, on favoriting a record using a favoriteRecord mutation, we can manually update our urql cache of Records
        // so that our frontend shows the new favorite record, without having to wait for the network request to get completed.
        // This helps in situations where showing instantaneous reactions in the UI is beneficial without having to wait for the
        // network request to finish. (Examples: Dragging and Dropping, Clicking Favorites etc)
        // Must be used with a manual cache update.
        // APIs and more info here: https://formidable.com/open-source/urql/docs/graphcache/cache-updates/#optimistic-updates
        optimistic: {
          ...promStatusOptimisticConfig,
          ...createFavoriteBackupOptimisticConfig,
        },
        keys: {
          TransactionAmountFilterRangeNode: () => null,
          InvoiceLineSummary: () => null,
          InvoiceNextSummary: () => null,
          TransactionSummaryNext: () => null,
          QuickClearResolveInfoResponse: () => null,
          InvoiceLineSummaryNext: () => null,
          UploadTemplateFileNode: () => null,
          BackupFileSummary: () => null,
          AccumulatedInvoiceLinesNode: () => null,
          TransactionAmountFilterRangeNodeNext: () => null,
          PromoRoiResponse: () => null,
          PromoRoiLineData: () => null,
          PaymentSummary: () => null,
          RemitSummary: () => null,
        },
      }),
      // This mapExchange aims to provide centralized error reporting
      // for all GraphQL operations that fail at the network or GraphQL
      // layer. This means that handlers of GraphQL operations should not
      // necessarily need to log their own generic errors. Doing so may
      // cause duplicate error reports.
      mapExchange({
        onError: (error: CombinedError, operation: Operation) => {
          console.error(
            `The ${operation.kind} ${operation.key} has errored with:`,
            error
          );
        },
      }),
      fetchExchange,
    ],
    ...options,
  });

export const client = makeVividlyClient({
  fetchOptions: { credentials: "include" },
});
