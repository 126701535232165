import { createTheme } from "@mui/material";

import { muiPaletteDark, muiPaletteLight } from "./palettes";
import {
  error,
  neutral,
  neutralVariant,
  primary,
  secondary,
  success,
  tertiary,
  warning,
} from "./colors";

const themeMode = "light";
const selectedPalette =
  themeMode === "light" ? muiPaletteLight : muiPaletteDark;

const normalTypeface = [
  "Monument Grotesk",
  "Arial",
  "Helvetica Neue",
  "Helvetica",
  "sans-serif",
].join(",");

const monoTypeface = [
  "Monument Grotesk Mono",
  "Consolas",
  "monaco",
  "monospace",
].join(",");

const originalTheme = createTheme({
  typography: {
    fontFamily: normalTypeface,
  },
  typeface: {
    normal: normalTypeface,
    mono: monoTypeface,
  },
});

const theme = createTheme(originalTheme, {
  typography: {
    displayLarge: {
      fontSize: originalTheme.typography.pxToRem(57),
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(64),
      letterSpacing: "-0.25px",
    },
    displayMedium: {
      fontSize: originalTheme.typography.pxToRem(45),
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(52),
    },
    displaySmall: {
      fontSize: originalTheme.typography.pxToRem(36),
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(44),
    },
    h1: {
      fontSize: originalTheme.typography.pxToRem(36),
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(44),
    },
    h2: {
      fontSize: originalTheme.typography.pxToRem(32),
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(40),
    },
    h3: {
      fontSize: originalTheme.typography.pxToRem(28),
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(36),
    },
    h4: {
      fontSize: originalTheme.typography.pxToRem(24),
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(32),
    },
    h5: {
      fontSize: originalTheme.typography.pxToRem(22),
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(28),
    },
    h6: {
      fontSize: originalTheme.typography.pxToRem(18),
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(24),
    },
    subtitle1: {
      fontSize: originalTheme.typography.pxToRem(16),
      fontWeight: 500,
      lineHeight: originalTheme.typography.pxToRem(24),
      letterSpacing: "0.1px",
    },
    subtitle2: {
      fontSize: originalTheme.typography.pxToRem(14),
      fontWeight: 500,
      lineHeight: originalTheme.typography.pxToRem(20),
      letterSpacing: "0.1px",
    },
    button: {
      fontSize: originalTheme.typography.pxToRem(14),
      fontWeight: 500,
      lineHeight: originalTheme.typography.pxToRem(20),
      letterSpacing: "0.1px",
    },
    caption: {
      fontSize: originalTheme.typography.pxToRem(12),
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(16),
      letterSpacing: "0.4px",
    },
    overline: {
      fontSize: originalTheme.typography.pxToRem(12),
      fontWeight: 500,
      lineHeight: originalTheme.typography.pxToRem(16),
      letterSpacing: "0.5px",
    },
    labelLarge: {
      fontSize: originalTheme.typography.pxToRem(14),
      fontWeight: 500,
      lineHeight: originalTheme.typography.pxToRem(20),
      letterSpacing: "0.1px",
    },
    labelMedium: {
      fontSize: originalTheme.typography.pxToRem(12),
      fontWeight: 500,
      lineHeight: originalTheme.typography.pxToRem(16),
      letterSpacing: "0.5px",
    },
    labelSmall: {
      fontSize: originalTheme.typography.pxToRem(11),
      fontWeight: 500,
      lineHeight: originalTheme.typography.pxToRem(16),
      letterSpacing: "0.5px",
    },
    labelLargeCondensed: {
      fontSize: originalTheme.typography.pxToRem(14),
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(16),
      letterSpacing: "0.1px",
    },
    labelMediumCondensed: {
      fontSize: originalTheme.typography.pxToRem(12),
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(16),
      letterSpacing: "0.1px",
    },
    labelSmallCondensed: {
      fontSize: originalTheme.typography.pxToRem(11),
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(16),
      letterSpacing: "0.1px",
    },
    bodyLarge: {
      fontSize: originalTheme.typography.pxToRem(16),
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(24),
      letterSpacing: "0.5px",
    },
    bodyMedium: {
      fontSize: originalTheme.typography.pxToRem(14),
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(20),
      letterSpacing: "0.25px",
    },
    bodySmall: {
      fontSize: originalTheme.typography.pxToRem(12),
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(16),
      letterSpacing: "0.4px",
    },
    bodyLargeBold: {
      fontSize: originalTheme.typography.pxToRem(16),
      fontWeight: 700,
      lineHeight: originalTheme.typography.pxToRem(24),
      letterSpacing: "0.5px",
    },
    bodyMediumBold: {
      fontSize: originalTheme.typography.pxToRem(14),
      fontWeight: 700,
      lineHeight: originalTheme.typography.pxToRem(20),
      letterSpacing: "0.25px",
    },
    bodySmallBold: {
      fontSize: originalTheme.typography.pxToRem(12),
      fontWeight: 700,
      lineHeight: originalTheme.typography.pxToRem(16),
      letterSpacing: "0.4px",
    },
    tabularLarge: {
      fontSize: originalTheme.typography.pxToRem(16),
      fontFamily: originalTheme.typeface.mono,
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(24),
      letterSpacing: "0.15px",
    },
    tabularMedium: {
      fontSize: originalTheme.typography.pxToRem(14),
      fontFamily: originalTheme.typeface.mono,
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(20),
      letterSpacing: "0.4px",
    },
    tabularSmall: {
      fontSize: originalTheme.typography.pxToRem(11),
      fontFamily: originalTheme.typeface.mono,
      fontWeight: 400,
      lineHeight: originalTheme.typography.pxToRem(16),
      letterSpacing: "0.4px",
    },
  },
  palette: {
    mode: themeMode,
    expanded: {
      error,
      neutral,
      neutralVariant,
      primary,
      secondary,
      success,
      tertiary,
      warning,
    },
    ...selectedPalette,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFeatureSettings: "normal !important",
          fontVariantCaps: "initial !important",
          fontVariantEastAsian: "initial !important",
          fontVariantLigaturesNormal: "initial !important",
          fontVariantNumeric: "normal !important",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "100px",
          "&:focus": {
            outline: "none",
          },
        },
        containedPrimary: {
          "&:hover": {
            backgroundColor: selectedPalette.primary.light,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:focus": {
            outline: "none",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          displayLarge: "h2",
          displayMedium: "h3",
          displaySmall: "h3",
          labelLarge: "p",
          labelMedium: "p",
          labelSmall: "p",
          labelLargeCondensed: "p",
          labelMediumCondensed: "p",
          labelSmallCondensed: "p",
          bodyLarge: "p",
          bodyMedium: "p",
          bodySmall: "p",
          bodyLargeTabular: "p",
          bodyMediumTabular: "p",
          bodySmallTabular: "p",
        },
      },
    },
    MuiTableBody: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          fontFamily: originalTheme.typeface.normal,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          lineHeight: "16px",
          letterSpacing: "0.4px",
          padding: "12px 16px",
          fontSize: "14px",
          borderBottomColor: "#E2E3DC",
          fontFamily: originalTheme.typeface.normal,
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          background: selectedPalette.readOnly.surface2,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minWidth: 80,
        },
        input: {
          fontFamily: originalTheme.typeface.normal,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          "& legend": {
            width: "auto",
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: selectedPalette.background.surfaceVariant,
          "&:hover": {
            backgroundColor: "#C6D1D5",
          },
          "&.Mui-focused": {
            backgroundColor: "#C6D1D5",
          },
        },
        input: {
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          "&:focus": {
            outline: "none",
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "none",
        color: "inherit",
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          "&:focus": {
            outline: "none",
          },
        },
      },
    },
    MuiStack: {
      defaultProps: {
        direction: "row",
        spacing: 1,
        alignItems: "center",
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: 0,
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          "& .Mui-selected": {
            backgroundColor: selectedPalette.primary.main,
            borderColor: `${selectedPalette.primary.onhover} !important`,
            "&:hover, &:focus": {
              backgroundColor: `${selectedPalette.primary.onhover} !important`,
              borderColor: `${selectedPalette.primary.onhover} !important`,
              outline: "none !important",
            },
          },
        },
      },
    },
    MuiDateRangePickerDay: {
      styleOverrides: {
        root: {
          "& .Mui-selected": {
            backgroundColor: selectedPalette.primary.main,
            borderColor: `${selectedPalette.primary.onhover} !important`,

            "&:hover, &:focus": {
              backgroundColor: `${selectedPalette.primary.onhover} !important`,
              borderColor: `${selectedPalette.primary.onhover} !important`,
              outline: "none !important",
            },
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          backgroundColor:
            originalTheme.palette.mode === "light"
              ? "rgb(226, 226, 226)"
              : "black",
          p: {
            margin: "0px",
          },
        },
      },
    },
    // MuiDialog: {
    //   styleOverrides: {
    //     paperFullWidth: {
    //       maxWidth: 1300,
    //     },
    //   },
    // },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default theme;
