import {
  FieldInfo,
  OptimisticMutationConfig,
  UpdatesConfig,
} from "@urql/exchange-graphcache";
import { v4 as uuid } from "uuid";

import {
  BackupSourcePartsFragmentDoc,
  CompanyPartsFragmentDoc,
  FavoriteBackupSourcesDocument,
  FavoriteBackupSourcesQuery,
} from "components/DeductionsNext/DeductionsScanning/DeductionScanner/queries.generated";
import {
  AddCompanyBackupSourceRelationshipMutation,
  DeleteCompanyBackupSourceRelationshipMutation,
  DeleteCompanyBackupSourceRelationshipMutationVariables,
} from "./mutations.generated";

const QUERY_KEY = "companyBackupSources";

export type AddCompanyBackupSourceRelationshipMutationInput = {
  company: string;
  backupSource: string;
};

type BatchCreateBackupFileMutationInputType = {
  filters: {
    company?: {
      id: {
        exact: string;
      };
    };
  };
};

type FieldInfoProm = FieldInfo & {
  arguments: BatchCreateBackupFileMutationInputType;
};

const createFavoriteBackupConfig: Partial<UpdatesConfig>["Mutation"] = {
  createCompanyBackupSourceRelationship(
    result: {
      createCompanyBackupSourceRelationship: AddCompanyBackupSourceRelationshipMutation["createCompanyBackupSourceRelationship"] & {
        isOptimistic?: boolean;
      };
    },
    args: { data: AddCompanyBackupSourceRelationshipMutationInput },
    cache,
    _info
  ) {
    const queryArgs = {
      filters: {
        company: {
          id: { exact: args?.data?.company },
        },
      },
    };

    const { isOptimistic } = result.createCompanyBackupSourceRelationship;

    cache.updateQuery(
      {
        query: FavoriteBackupSourcesDocument,
        variables: {
          ...queryArgs,
        },
      },
      (data: FavoriteBackupSourcesQuery | null) => {
        if (data?.companyBackupSources?.length === 0 && isOptimistic) {
          return null;
        }

        data?.companyBackupSources?.push(
          result.createCompanyBackupSourceRelationship
        );
        return data;
      }
    );
  },
  deleteCompanyBackupSourceRelationships(
    result: DeleteCompanyBackupSourceRelationshipMutation,
    _args: { data: DeleteCompanyBackupSourceRelationshipMutationVariables },
    cache,
    _info
  ) {
    (cache.inspectFields("Query") as FieldInfoProm[])
      .filter((field) => field.fieldName === QUERY_KEY)
      .forEach((field) => {
        const { arguments: args } = field;

        if (args?.filters?.company) {
          return cache.invalidate("Query", field.fieldKey);
        }

        return null;
      });
  },
};

// Optimistic function, which assumes the happy return value
// For the createCompanyBackupSourceRelationship mutation, against which the
// updater function in run till we get back actual network response.
// Note: The optimistic return value MUST contain all the fields
// returned by the actual mutation response
// https://formidable.com/open-source/urql/docs/graphcache/cache-updates/#optimistic-updates
const createFavoriteBackupOptimisticConfig: OptimisticMutationConfig = {
  createCompanyBackupSourceRelationship(
    args: { data: AddCompanyBackupSourceRelationshipMutationInput },
    cache
  ) {
    const backupSource = cache.readFragment(BackupSourcePartsFragmentDoc, {
      id: args.data.backupSource,
    });

    const company = cache.readFragment(CompanyPartsFragmentDoc, {
      id: args.data.company,
    });

    return {
      __typename: "CompanyBackupSourceRelationshipNode",
      backupSource: {
        ...backupSource,
        __typename: "BackupSourceNode",
      },
      company: {
        ...company,
        __typename: "CompanyNode",
      },
      id: uuid(),
      isOptimistic: true,
    };
  },
};

export { createFavoriteBackupConfig, createFavoriteBackupOptimisticConfig };
