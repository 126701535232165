import config, { isRunningTests } from "./config";

const {
  projectId,
  cresicorNextServiceURL,
  analyticsServiceURL,
  budgetServiceURL,
  forecastServiceURL,
  nodeServiceURL,
  apiRootPath,
  stytch,
  celloCredentials,
} = config;
const MUILicenseKey =
  "05c90e79c607255eb2188a814bdf5482T1JERVI6MzAyNTQsRVhQSVJZPTE2NjQ3Mzg5OTEwMDAsS0VZVkVSU0lPTj0x"; // pragma: allowlist secret
const sentryDSN =
  "https://bd664d5abac745dea77c02191117b05f@o75046.ingest.sentry.io/6075010";

const nodeApiRoot = "/node-api";
const nodeApi = `${nodeServiceURL}${nodeApiRoot}`;
export {
  projectId,
  MUILicenseKey,
  sentryDSN,
  cresicorNextServiceURL,
  analyticsServiceURL,
  budgetServiceURL,
  forecastServiceURL,
  nodeServiceURL,
  apiRootPath,
  nodeApiRoot,
  stytch,
  celloCredentials,
  isRunningTests,
  nodeApi,
};
