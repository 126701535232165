import {
  Tier,
  DeductionMatchingColumnHeaders,
  FundTypeFieldChoices,
} from "__generated__/graphql";

export enum MONTHS {
  JAN = "January",
  FEB = "February",
  MAR = "March",
  APR = "April",
  MAY = "May",
  JUN = "June",
  JUL = "July",
  AUG = "August",
  SEP = "September",
  OCT = "October",
  NOV = "November",
  DEC = "December",
}

export const monthsFullName = Object.values(MONTHS);

export enum PROMOTION_SORT_BY {
  UPDATED_DATE = "updatedAt",
  EXPECTED_SPEND = "totalExpSpend",
  START_DATE = "start_date",
}

export enum PROMOTION_VIEWS {
  COLUMNS = "columns",
  LIST = "list",
  CALENDAR = "calendar",
  ADVANCED_DUPLICATION = "advanced-duplication",
  CANCELLED = "cancelled",
  SUBMISSION = "submission",
  DECLINED = "declined",
  COMPLETED = "completed",
  RUNNING = "running",
  MASTER_EDITOR = "masterEditor",
  APPROVAL = "approval",
  LINES_LIST = "linesList",
  CLOSED = "closed",
}

export enum PROMOTION_LINE_FUND_TYPE_FIELDS {
  totalExpSpend = "Total Expected Spend ($)",
  totalExpSales = "Total Expected Units",
  // Might need these fields for analytics need to check with CST and analytics team.
  // prevTotalExpSales = "Previous Total Expected Sales",
  // prevTotalExpSpend = "Previous Total Expected Spend",
  buyinStartDate = "Buy In Start Date",
  buyinEndDate = "Buy In End Date",
  instoreStartDate = "In Store Start Date",
  instoreEndDate = "In Store End Date",
  scanbackStartDate = "Scan Back Start Date",
  scanbackEndDate = "Scan Back End Date",
  lumpSumSpend = "Lump sum amount ($)",
  spendRate = "Spend Rate ($)",
  lift = "Expected Lift (%)",
  actualLift = "Actual Lift (%)",
  basePrice = "Base Price($)",
  retailPrice = "Retail Price($)",
}

export const FUND_TYPE_FIELD_TO_FUND_TYPE_MAP = {
  buyinStartDate: "BUY",
  buyinEndDate: "BUY",
  instoreStartDate: "STORE",
  instoreEndDate: "STORE",
  scanbackStartDate: "SCAN",
  scanbackEndDate: "SCAN",
  lumpSumSpend: "SUM",
  spendRate: "RATE",
  totalExpSales: "RATE",
  lift: "LIFT",
  basePrice: "PRICING",
  retailPrice: "PRICING",
};

export const FIELD_TYPE_TO_FIELDS_MAP = {
  [FundTypeFieldChoices.Buy]: {
    start: "buyinStartDate",
    end: "buyinEndDate",
  },
  [FundTypeFieldChoices.Store]: {
    start: "instoreStartDate",
    end: "instoreEndDate",
  },
  [FundTypeFieldChoices.Scan]: {
    start: "scanbackStartDate",
    end: "scanbackEndDate",
  },
} as const;

// Labels are defined in the enums.py file in backend folder
export const DeductionsMatchingColumnHeaderLabels = {
  [DeductionMatchingColumnHeaders.Amount]: "Amount",
  [DeductionMatchingColumnHeaders.CustomerName]: "Customer Name",
  [DeductionMatchingColumnHeaders.CustomerNameAcc]:
    "Customer Name (Accumulation)",
  [DeductionMatchingColumnHeaders.Date]: "Date",
  [DeductionMatchingColumnHeaders.FundTypes]: "Fund Type(s)",
  [DeductionMatchingColumnHeaders.ProductName]: "Product Name",
  [DeductionMatchingColumnHeaders.SpendRate]: "Spend Rate",
};

export const TIER_ORDER = [Tier.Emerging, Tier.Growth, Tier.Scale];

export enum DomainTypes {
  BLACKLISTED = "BLACKLIST",
  WHITELISTED = "WHITELIST",
  USED = "USED",
}

export enum SortDir {
  ASC = "ASC",
  DESC = "DESC",
}

export enum MatchConfidence {
  HIGH = "HIGH",
  LOW = "LOW",
  NEEDS_ATTENTION = "NEEDS_ATTENTION",
}

export enum DEPLOYMENT_ENVIRONMENT {
  DEV = "dev",
  BETA = "beta",
  PROD = "prod",
}
