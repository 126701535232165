const projectId = "cresicor-db1c9";
const stytchPublicToken =
  "public-token-live-23c94a0a-d63c-47d5-b0be-2a3c745a252a";
const celloProductId = "www.govividly.com";
const celloScriptUrl = "https://assets.cello.so/app/latest/cello.js";

export default {
  projectId,
  stytchPublicToken,
  celloProductId,
  celloScriptUrl,
};
