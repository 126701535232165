const projectId = "cresicor-dev";
const stytchPublicToken =
  "public-token-test-8798b5ed-ad84-4226-91e6-cc2b76ce6b28";
const celloProductId = "stage-www.govividly.com";
const celloScriptUrl = "https://assets.sandbox.cello.so/app/latest/cello.js";

export default {
  projectId,
  stytchPublicToken,
  celloProductId,
  celloScriptUrl,
};
