import * as Types from '../../../../__generated__/graphql';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type QueryInternalFilesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type QueryInternalFilesQuery = { allInternalFiles: Array<{ visibleName: string, id: string, backupSources: Array<{ id: string, displayName: string }> }> };

export type GetInternalFileQueryVariables = Types.Exact<{
  internalFileId: Types.Scalars['UUID'];
}>;


export type GetInternalFileQuery = { internalFile: { id: string, fileData: any, visibleName: string, fileType: Types.FileTypes } };

export type FundTypesQueryVariables = Types.Exact<{
  fundTypeFilter: Types.FundTypeFilter;
}>;


export type FundTypesQuery = { fundTypes: Array<{ id: string, name: string }> };

export type BackupSourcePartsFragment = { id: string, displayName: string };

export type CompanyPartsFragment = { id: string, name: string };

export type FavoriteBackupSourcesQueryVariables = Types.Exact<{
  filters: Types.CompanyBackupSourceRelationshipFilter;
}>;


export type FavoriteBackupSourcesQuery = { companyBackupSources: Array<{ id: string, backupSource: { id: string, displayName: string }, company: { id: string, name: string } }> };

export const BackupSourcePartsFragmentDoc = gql`
    fragment BackupSourceParts on BackupSourceNode {
  id
  displayName
}
    `;
export const CompanyPartsFragmentDoc = gql`
    fragment CompanyParts on CompanyNode {
  id
  name
}
    `;
export const QueryInternalFilesDocument = gql`
    query queryInternalFiles {
  allInternalFiles(filters: {category: BACKUP_SOURCE_TEMPLATE}) {
    visibleName
    id
    backupSources {
      id
      displayName
    }
  }
}
    `;

export function useQueryInternalFilesQuery(options?: Omit<Urql.UseQueryArgs<QueryInternalFilesQueryVariables>, 'query'>) {
  return Urql.useQuery<QueryInternalFilesQuery>({ query: QueryInternalFilesDocument, ...options });
};
export const GetInternalFileDocument = gql`
    query getInternalFile($internalFileId: UUID!) {
  internalFile(id: $internalFileId) {
    id
    fileData
    visibleName
    fileType
  }
}
    `;

export function useGetInternalFileQuery(options: Omit<Urql.UseQueryArgs<GetInternalFileQueryVariables>, 'query'>) {
  return Urql.useQuery<GetInternalFileQuery>({ query: GetInternalFileDocument, ...options });
};
export const FundTypesDocument = gql`
    query fundTypes($fundTypeFilter: FundTypeFilter!) {
  fundTypes(filters: $fundTypeFilter) {
    id
    name
  }
}
    `;

export function useFundTypesQuery(options: Omit<Urql.UseQueryArgs<FundTypesQueryVariables>, 'query'>) {
  return Urql.useQuery<FundTypesQuery>({ query: FundTypesDocument, ...options });
};
export const FavoriteBackupSourcesDocument = gql`
    query favoriteBackupSources($filters: CompanyBackupSourceRelationshipFilter!) {
  companyBackupSources(filters: $filters) {
    id
    backupSource {
      ...BackupSourceParts
    }
    company {
      ...CompanyParts
    }
  }
}
    ${BackupSourcePartsFragmentDoc}
${CompanyPartsFragmentDoc}`;

export function useFavoriteBackupSourcesQuery(options: Omit<Urql.UseQueryArgs<FavoriteBackupSourcesQueryVariables>, 'query'>) {
  return Urql.useQuery<FavoriteBackupSourcesQuery>({ query: FavoriteBackupSourcesDocument, ...options });
};