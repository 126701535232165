import { forwardRef, useCallback } from "react";
import { useSnackbar, SnackbarContent, CustomContentProps } from "notistack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Card } from "@mui/material";
import ButtonNext from "./ButtonNext";

interface DrmSnackbarProps extends CustomContentProps {
  link?: {
    name: string;
    onClick: () => void;
  };
  jsx?: JSX.Element;
}

const DrmSnackbar = forwardRef<HTMLDivElement, DrmSnackbarProps>(
  ({ id, message, link, jsx }, ref) => {
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);
    return (
      <SnackbarContent ref={ref} style={{ minWidth: "375px" }}>
        <Card
          sx={{
            width: "100%",
            backgroundColor: (theme) => theme.palette.expanded.neutral[20],
            color: (theme) => theme.palette.expanded.neutral[95],
            display: "flex",
            justifyContent: "space-between",
            p: "14px 8px 14px 16px",
            alignItems: "center",
            borderRadius: "4px",
          }}
          elevation={3}
        >
          {jsx ?? <Typography variant="bodyMedium">{message}</Typography>}
          {link ? (
            <ButtonNext
              variant="text"
              sx={{
                padding: 0,
                ml: "25px",
                mr: "8px",
                mt: "2px",
                color: (theme) => theme.palette.expanded.primary[80],
              }}
              onClick={() => {
                link.onClick();
                handleDismiss();
              }}
            >
              {link.name}
            </ButtonNext>
          ) : null}
          <IconButton
            onClick={handleDismiss}
            sx={{ height: "24px", color: "white" }}
          >
            <CloseIcon color="inherit" />
          </IconButton>
        </Card>
      </SnackbarContent>
    );
  }
);

export default DrmSnackbar;
