/* eslint-disable no-restricted-globals */
import { env } from "./env";
import devConstants from "./constants/dev";
import prodConstants from "./constants/prod";

export const isRunningTests = () => {
  return process.env.JEST_WORKER_ID !== undefined;
};

interface StytchConfig {
  publicToken: string;
}

interface CelloCredentials {
  productId: string;
  scriptURL: string;
}

interface EnvironmentConfig {
  projectId: string;
  cresicorNextServiceURL: string;
  analyticsServiceURL: string;
  budgetServiceURL: string;
  forecastServiceURL: string;
  nodeServiceURL: string;
  apiRootPath: string;
  stytch: StytchConfig;
  celloCredentials: CelloCredentials;
}

interface Config {
  beta: EnvironmentConfig;
  dev: EnvironmentConfig;
  prod: EnvironmentConfig;
  sandbox: EnvironmentConfig;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const config: Config = {
  beta: {
    projectId: devConstants.projectId,
    cresicorNextServiceURL: location.origin,
    analyticsServiceURL: location.origin,
    budgetServiceURL: location.origin,
    forecastServiceURL: location.origin,
    nodeServiceURL: location.origin,
    apiRootPath: `api/`,
    stytch: {
      publicToken: devConstants.stytchPublicToken,
    },
    celloCredentials: {
      productId: devConstants.celloProductId,
      scriptURL: devConstants.celloScriptUrl,
    },
  },
  dev: {
    projectId: devConstants.projectId,
    cresicorNextServiceURL: `http://localhost:8002`,
    analyticsServiceURL: `http://localhost:8081`,
    budgetServiceURL: `http://localhost:8080`,
    forecastServiceURL: `http://localhost:8080`,
    nodeServiceURL: `http://localhost:8083`,
    apiRootPath: `api/`,
    stytch: {
      publicToken: devConstants.stytchPublicToken,
    },
    celloCredentials: {
      productId: devConstants.celloProductId,
      scriptURL: devConstants.celloScriptUrl,
    },
  },
  prod: {
    projectId: prodConstants.projectId,
    cresicorNextServiceURL: location.origin,
    analyticsServiceURL: location.origin,
    budgetServiceURL: location.origin,
    forecastServiceURL: location.origin,
    nodeServiceURL: location.origin,
    apiRootPath: `api/`,
    stytch: {
      publicToken: prodConstants.stytchPublicToken,
    },
    celloCredentials: {
      productId: prodConstants.celloProductId,
      scriptURL: prodConstants.celloScriptUrl,
    },
  },
  sandbox: {
    projectId: devConstants.projectId,
    cresicorNextServiceURL: location.origin,
    analyticsServiceURL: location.origin,
    budgetServiceURL: location.origin,
    forecastServiceURL: location.origin,
    nodeServiceURL: location.origin,
    apiRootPath: `api/`,
    stytch: {
      publicToken: devConstants.stytchPublicToken,
    },
    celloCredentials: {
      productId: devConstants.celloProductId,
      scriptURL: devConstants.celloScriptUrl,
    },
  },
};

if (!isRunningTests()) {
  console.info(`using configuration for environment ${env}`);
}

if (!(env in config)) {
  throw new Error(`Invalid environment: ${env}`);
}

const selectedEnv = env as keyof Config;

export default config[selectedEnv];
